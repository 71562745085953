import React from 'react';
import { useState } from 'react';

import type { UploadProps } from 'antd';
import { Modal, Upload, message, Layout } from "antd";

import { UploadOutlined } from '@ant-design/icons';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

import { saveAs } from 'file-saver';

const { Content } = Layout;
const { Dragger } = Upload;

const containerStyle = {
  maxWidth: '900px', // Max width for large screens
  width: '100%',      // Make it take full width when smaller than max width
  padding: '16px',    // Optional padding for inner content
};

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh', // Full screen height to ensure vertical centering
};

const formContainerStyle = {
  maxWidth: '400px', // Max width of the form container
  width: '100%',     // Ensure it fills smaller screens fully
  padding: '16px',   // Padding around the form for better UX
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: a subtle shadow for better aesthetics
  borderRadius: '8px', // Optional: rounded corners
  backgroundColor: '#fff', // Optional: white background
};


const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [key, setKey] = useState('');

  // Used to turn an error during the upload to a visible error message
  function getError(option: any, xhr: XMLHttpRequest) {
    const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
    const err = new Error(msg) as any;
    err.status = xhr.status;
    err.method = option.method;
    err.url = option.action;
    return err;
  }

  const props: UploadProps = {
    accept: ".jpg,.jpeg,image/jpeg",
    name: 'file',
    multiple: true,
    listType: "picture",
    action: '/api/upload',
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: true
    },

    // We need a custom request since we need to set the response type to blob
    customRequest(option: any) {
      const xhr = new XMLHttpRequest();

      // Display progress
      if (option.onProgress && xhr.upload) {
        xhr.upload.onprogress = function progress(e: any) {
          if (e.total > 0) {
            e.percent = (e.loaded / e.total) * 100;
          }
          option.onProgress(e);
        };
      }

      // eslint-disable-next-line no-undef
      const formData = new FormData();

      // eslint-disable-next-line no-undef
      if (option.file instanceof Blob) {
        formData.append(option.filename, option.file, (option.file as any).name);
      } else {
        formData.append(option.filename, option.file);
      }

      // In case of network errors
      xhr.onerror = function error(e) {
        option.onError(e);
      };

      xhr.onload = function onload() {
        // allow success when 2xx status
        // see https://github.com/react-component/upload/issues/34
        if (xhr.status < 200 || xhr.status >= 300) {
          return option.onError(getError(option, xhr), xhr.response);
        }

        return option.onSuccess(xhr.response, xhr);
      };

      xhr.open(option.method, option.action, true);

      // Set authorization header with the api key
      xhr.setRequestHeader("Authorization", "Bearer " + key);

      // Service returns a jpeg image, binary response expected
      xhr.responseType = "blob";

      xhr.send(formData);

      return {
        abort() {
          xhr.abort();
        },
      }
    },

    // Replace the filename and add an -exif extension when someone tries to download the file
    onDownload(file) {
      const regex = /(\.jpg)$/i;
      saveAs(file.response, file.name.replace(regex, "-exif.jpg"));
    },

    // Handle upload status change, only ui stuff
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log("Response: " + info.file.response);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    // File has been removed, almost nothing to do
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  // User submits API key
  const onFinish = (values: any) => {
    setLoginProcessing(true);
    const xhr = new XMLHttpRequest();

    // Should the login fail due to a network error or so
    xhr.onerror = function error(e) {
      setLoginProcessing(false);
      Modal.error({
        title: 'Login failed',
        content: 'Maybe a network error?',
      });
    };

    // Shoud the request work well
    xhr.onload = function (event) {
      setLoginProcessing(false);
      if (xhr.status < 200 || xhr.status >= 300) {
        // Key was incorrect
        Modal.error({
          title: 'Login failed',
          content: 'Try a different key!',
        });
      } else {
        // Key is correct
        console.log("Response: " + xhr.response);
        setKey(values['apikey']);
        setLoggedIn(true);
      }
    }

    xhr.open("GET", "/api/test");
    xhr.setRequestHeader("Authorization", "Bearer " + values['apikey']);
    xhr.send();

  };


  if (loggedIn) {
    // Render the uploader
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
          <div style={containerStyle}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Click or drag jpeg images to this area to upload</p>
              <p className="ant-upload-hint">
                Camera and lens model and settings as well as colour profiles will be preserved. Other meatadata will be stripped.
              </p>
            </Dragger>
          </div>
        </Content>
      </Layout>
    );
  } else {
    // Render the login dialog
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={contentStyle}>
          <div style={formContainerStyle}>
            <Form
              name="login"
              initialValues={{ remember: true }}
              style={{ maxWidth: 360 }}
              onFinish={onFinish}
              disabled={loginProcessing}
            >
              <Form.Item
                name="apikey"
                rules={[{ required: true, message: 'Please input your API Key!' }]}
              >
                <Input prefix={<LockOutlined />} type="password" placeholder="Key" />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    );
  }

};

export default App;